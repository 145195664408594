import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat } from '@common-src/filter';
import moment, { Moment } from 'moment';

export class DutyShiftEntityModel extends BaseEntityModel {
    @FormControl({
        label: '班次名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '上班时间',
        type: FormControlType.TIME_PICKER,
        required: true
    } as FormControlTextModel)
    startHour: string = undefined;

    @FormControl({
        label: '下班时间',
        type: FormControlType.TIME_PICKER,
        required: true
    } as FormControlTextModel)
    endHour: string = undefined;

    duration: string = undefined;
    updateDate: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '班次时间',
                dataIndex: 'dutyTime',
                customRender: (text, record, index) => {
                    if (moment(record.startHour).format('YYYY-MM-DD') !== moment(record.endHour).format('YYYY-MM-DD')) {
                        return moment(record.startHour).format('HH:mm') + ' - 次日' + moment(record.endHour).format('HH:mm');
                    }
                    return moment(record.startHour).format('HH:mm') + ' - ' + moment(record.endHour).format('HH:mm');
                }
            },
            {
                title: '班次时长',
                dataIndex: 'duration',
                customRender: (text, record) => {
                    return record.timeFilter(text, {});
                }
            },
            {
                title: '更新时间',
                dataIndex: 'updateDate',
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        delete data.duration;
        delete data.updateDate;
        if (data.endHour <= data.startHour) {
            data.endHour = moment(data.endHour).add(1, 'day');
        }
        return data;
    }

    timeFilter(secdons, item) {
        if (!item.timeFormat) {
            item.timeFormat = '';
        }
        if (secdons < 3600) {
            var min;
            min = Math.floor(secdons / 60);
            item.timeFormat = item.timeFormat + min + '分钟';
        } else if (secdons < 86400) {
            var hour;
            hour = Math.floor(secdons / 3600);
            item.timeFormat = item.timeFormat + hour + '小时';
            this.timeFilter(secdons - hour * 3600, item);
        } else {
            var day;
            day = Math.floor(secdons / 86400);
            item.timeFormat = item.timeFormat + day + '天';
            this.timeFilter(secdons - day * 86400, item);
        }

        return item.timeFormat;
    }
}

export class DutyShiftQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
