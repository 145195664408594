
import { DutyShiftEntityModel, DutyShiftQueryModel } from '@common-src/entity-model/duty-shift-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${ASSET_BASE_REQUEST_PATH}/shift`;

class DutyShiftService implements ICRUDQ<DutyShiftEntityModel, DutyShiftQueryModel> {
    async create(model: DutyShiftEntityModel):Promise<DutyShiftEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<DutyShiftEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new DutyShiftEntityModel().toModel(res);
    }

    async update(model: DutyShiftEntityModel):Promise<DutyShiftEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: DutyShiftEntityModel):Promise<DutyShiftEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: DutyShiftQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new DutyShiftEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new DutyConfigEntityModel().toModel(item));
    }

    async getAll():Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = { page: 1, limit: 999 };
        const res = await post(url, params);
        return res.items.map(item => {
            return {
                value: item.id,
                name: item.name
            };
        });
    }
}

export default new DutyShiftService();
